@keyframes hideShow1 {
    0%, 33% { display: inherit;  }
    34%, 100% { display: none; }
}
  
@keyframes hideShow2 {
    0%, 34% { display: none; }
    35%, 66% { display: inherit; }
    67%, 100% { display: none; }
}
  
@keyframes hideShow3 {
  0%, 67% { display: none; }
  68%, 100% { display: inherit; }
}
  
.animated-icon-container > .hide-show1 {
animation: hideShow1 3s linear infinite;
}

.animated-icon-container > .hide-show2 {
animation: hideShow2 3s linear infinite;
}

.animated-icon-container > .hide-show3 {
animation: hideShow3 3s linear infinite;
}
  